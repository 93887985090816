import React from "react";
import { Flex, Button, Box, Text } from "rebass";
import styled from "@emotion/styled";

import strings from "./strings.json";

const { ariaIncreaseButton, ariaDecreaseButton } = strings;

const StyledButton = styled(Button)`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  text-align: center;
  line-height: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: inherit;
  padding-top: 2px;
  padding-bottom: 2px;
  border: 0;
  border-radius: 10px;
  padding-left: 4px;
  padding-right: 4px;
  width: 20px;
  color: black;
  cursor: pointer;
  width: 20px;
  &:focus {
    outline: none;
  }
`;

const CountWrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  color: white;
  width: 4rem;
  height: 2rem;
  min-width: 0;
  right: 1.25rem;
  font-size: 0.75rem;
  bottom: 4rem;
  display: flex;
`;

const PmWrap = styled.div`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  text-align: left;
  font-size: 0.75rem;
`;

const QuantWrap = styled.div`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 33.33333333333333%;
  text-align: center;
  padding: 2px;
`;

const QuantText = styled.div`
  box-sizing: border-box;
  margin: 0;
  font-weight: 500;
  color: black;
  min-width: 0;
  text-align: center;
`;

function ProductCounter({ currentAmount, increaseAmount, decreaseAmount }) {
  return (
    <CountWrapper>
      <PmWrap>
        <StyledButton
          onClick={decreaseAmount}
          aria-label={ariaDecreaseButton}
          variant="increase-decrease"
          px={1}
          outline="none"
        >
          -
        </StyledButton>
      </PmWrap>

      <QuantWrap>
        <QuantText textAlign="center">{currentAmount}</QuantText>
      </QuantWrap>
      <PmWrap>
        <StyledButton
          onClick={increaseAmount}
          aria-label={ariaIncreaseButton}
          variant="increase-decrease"
          px={1}
        >
          +
        </StyledButton>
      </PmWrap>
    </CountWrapper>
  );
}

export default ProductCounter;
