import React from "react";
import { graphql } from "gatsby";
import Header from "../../components/Header/Header.jsx";
import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import styled from "@emotion/styled";
import SEO from "~/components/seo";
import ProductForm from "~/components/ProductForm";
import {
  Img,
  Container,
  TwoColumnGrid,
  GridLeft,
  GridRight,
} from "~/utils/styles";
import { ProductTitle, ProductDescription } from "./styles";
import ProductTabs from "../../components/ProductTabs/ProductTabs";

export const Imagewrap = styled.div`
  img {
    border-style: none;
    max-width: 80%;
    height: auto;
    border-radius: 5px 5px;
    opacity: 0.93;
    @media (max-width: 1000px) {
      max-width: 60%;
    }
    @media (max-width: 600px) {
      margin-left: 0%;
    }
  }
`;

export const Svgstyle = styled.span`
  svg {
    top: 0;
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
    margin-right: 4px;
    vertical-align: middle;
  }
`;

export const IndivP = styled.div`
  width: 100%;
  z-index: 12;
  margin-top: 200px;
  margin-bottom: 7rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10%;
  padding-right: 10%;
  @media (max-width: 600px) {
    margin-bottom: 15rem;
  }
`;

export const Lforward = styled.div`
  text-align: right;
  font-size: 0.9rem;
  color: #3b6490;
`;

export const Lback = styled.div`
  text-align: left;
  font-size: 0.7rem;
  opacity: 0.75;
`;

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct;

  return (
    <>
      <SEO title={product.title} description={product.description} />
      <IndivP>
        <Container>
          <TwoColumnGrid>
            <GridLeft>
              <Imagewrap>
                <img
                  src={product.images[0].originalSrc}
                  alt={product.title}
                ></img>
              </Imagewrap>
            </GridLeft>
            <GridRight>
              <ProductTitle>{product.title}</ProductTitle>
              <ProductDescription
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
              <ProductForm product={product} />
            </GridRight>
          </TwoColumnGrid>
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="flex-end"
          >
            <GridItem xs={4}>
              <AniLink cover direction="left" to="/" bg="#4C83BE">
                <Lback>
                  {" "}
                  <Svgstyle>
                    <KeyboardBackspaceIcon />
                  </Svgstyle>
                  CAIN
                </Lback>
              </AniLink>
            </GridItem>
            <GridItem xs={4}></GridItem>
            <GridItem xs={4}>
            <a href="https://culrosssolutions.com">
                <Lforward>
                  Culross Solutions
                  <Svgstyle>
                    <ArrowRightAltIcon />
                  </Svgstyle>
                </Lforward>
                </a>
            </GridItem>
          </GridContainer>
        </Container>
      </IndivP>
    </>
  );
};

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default ProductPage;
