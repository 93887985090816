import React, { useState, useContext, useEffect, useCallback } from "react";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ProductCounter from "../ProductCounter";
import OosForm from "../OosForm/OosForm.js";
import PoaForm from "../PoaForm/PoaForm.js";
import Button from "../CustomButtons/Button.jsx";
import StoreContext from "~/context/StoreContext";
import styled from "@emotion/styled";
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
// import { Link } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const ButtonText = styled.div`
word-spacing: 0.25rem;
letter-spacing: 0.15rem; 
  }
`;

export const Lforward = styled.div`
text-align: right;
  }
`;

export const Lback = styled.div`
text-align: left;
  }
`;

export const Container = styled.div` 
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2rem;
  width: 100%;
  @media (min-width: 576px): {
    max-width: 540px;
  }
  @media (min-width: 768px): {
    max-width: 720px;
  }
  @media (min-width: 992px): {
    max-width: 960px;
  }
  @media (min-width: 1200px)": {
    max-width: 1140px;
  }
}
`;

export const CartButton = styled.span`
  }
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProductForm = ({ product }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRange: { minVariantPrice },
  } = product;
  const [variant, setVariant] = useState({ ...initialVariant });
  // const [quantity, setQuantity] = useState(1);
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext);

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;
  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailability = useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          (variant) => variant.id === productVariant.shopifyId
        );
        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [client.product, productVariant.shopifyId, variants]
  );

  useEffect(() => {
    checkAvailability(product.shopifyId);
  }, [productVariant, checkAvailability, product.shopifyId]);

  const handleQuantityChange = ({ quantity }) => {
    setQuantity(quantity.value);
  };

  const handleOptionChange = (optionIndex, { target }) => {
    const { value } = target;
    const currentOptions = [...variant.selectedOptions];

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    };

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    );

    setVariant({ ...selectedVariant });
  };

  const [open, setOpen] = React.useState(false);

  const handleAdd = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [quantity, setQuantity] = useState(1);

  function increaseAmount() {
    setQuantity((a) => a + 1);
  }

  function decreaseAmount() {
    setQuantity((a) => (a <= 1 ? 1 : a - 1));
  }

  const handleAddToCart = () => {
    if (quantity < 1) {
      alert("A Minimum order of 1" + " " + product.title + " is required.");
    } else {
      addVariantToCart(productVariant.shopifyId, quantity);
      setOpen(true);
    }
  };

  /* 
  Using this in conjunction with a select input for variants 
  can cause a bug where the buy button is disabled, this 
  happens when only one variant is available and it's not the
  first one in the dropdown list. I didn't feel like putting 
  in time to fix this since its an edge case and most people
  wouldn't want to use dropdown styled selector anyways - 
  at least if the have a sense for good design lol.
  */
  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    });
    if (match === undefined) return true;
    if (match.availableForSale === true) return false;
    return true;
  };

  const price = Intl.NumberFormat(undefined, {
    currency: minVariantPrice.currencyCode,
    minimumFractionDigits: 2,
    style: "currency",
  }).format(variant.price);

  let poastatus = false;

  function poa(price) {
    if (variant.price == 0.0) {
      poastatus = true;
      return "Price on Application";
    } else {
      return price;
    }
  }

  return (
    <>
      <h3>{poa(price)}</h3>
      {options.map(({ id, name, values }, index) => (
        <React.Fragment key={id}>
          {/* <label htmlFor={name}>{name} </label>
          <select
            name={name}
            key={id}
            onChange={(event) => handleOptionChange(index, event)}
          >
            {values.map((value) => (
              <option
                value={value}
                key={`${name}-${value}`}
                disabled={checkDisabled(name, value)}
              >
                {value}
              </option>
            ))}
          </select>
          <br /> */}
        </React.Fragment>
      ))}


     {poastatus && (
        <div>
          <br />
          <p>
            Please fill out the form below with a brief outline of what you
            require along with your email address and we will be in touch as
            soon as possible.
          </p>
          <br />
          <PoaForm />
        </div>
      )}

      {!poastatus && (
        <div>
          <label htmlFor="quantity">Quantity </label>

          <ProductCounter
            decreaseAmount={decreaseAmount}
            increaseAmount={increaseAmount}
            currentAmount={quantity}
          />

          <Button
            color="primary"
            size="xs"
            target="_blank"
            rel="noopener noreferrer"
            type="submit"
            disabled={!available || adding || poastatus}
            onClick={handleAddToCart}
          >
            <ButtonText>Add to cart</ButtonText>
          </Button>
        </div>
      )}

      <Snackbar open={open} autoHideDuration={4000} onClose={handleAdd}>
        <Alert onClose={handleAdd} severity="success">
          {quantity} {product.title} added to cart
        </Alert>
      </Snackbar>

      {!available && (
        <div>
          <br />
          <p>This Product is out of Stock!</p>
          <br />
          <OosForm />
        </div>
      )}
      {/* <div>
        <CartButton>
          <AniLink paintDrip to="/cart" hex="#4caf50">
            <Button color="success">
              <ShoppingCartIcon />
              Cart
            </Button>
          </AniLink>
        </CartButton>
      </div> */}
    </>
  );
};

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
};

export default ProductForm;
