import { container } from "../material-kit-react.jsx";

const tabsStyle = {
  section: {
    background: "#EEEEEE",
    padding: "70px 0",
  },
  container,
  textCenter: {
    fontSize: "13px",
    opacity: "0.8",
  },
};

export default tabsStyle;
