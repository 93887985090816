const cardBodyStyle = (theme) => ({
  cardBody: {
    padding: "0.9375rem 1.875rem",
    flex: "1 1 auto",
    height: "15rem",
    [theme.breakpoints.down("md")]: {
      height: "20rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "24rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "33rem",
    },
  },
});

export default cardBodyStyle;
