import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import CustomTabs from "../CustomTabs/CustomTabs.jsx";
import tabsStyle from "../../assets/jss/material-components/tabsStyle.jsx";

class ProductTabs extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          plainTabs
          headerColor="primary"
          tabs={[
            {
              tabName: "Shipping",
              tabContent: (
                <p className={classes.textCenter}>
                  Shipping rates are calculated at checkout.
                  <br />
                  <br />
                  UK delivery within 3-5 days.
                  <br />
                </p>
              ),
            },
            {
              tabName: "Returns",
              tabContent: (
                <p className={classes.textCenter}>
                  You have 30 calendar days to return an item from the date you
                  received it.
                  <br />
                  <br />
                  To be eligible for a return, your item must be unused and in
                  the same condition that you received it.
                  <br />
                  <br />
                  You must inform us via email that you will return the product
                  <br />
                  <br />
                  Your item must be in the original packaging.
                  <br />
                  <br />
                  You will be responsible for paying for your own shipping costs
                  for returning your item.
                  <br />
                  <br />
                  Your item needs to have the receipt or proof of purchase.
                </p>
              ),
            },
            {
              tabName: "Refunds",
              tabContent: (
                <p className={classes.textCenter}>
                  Once we receive your item, we will inspect it and notify you
                  that we have received your returned item. We will immediately
                  notify you on the status of your refund after inspecting the
                  item.
                  <br />
                  <br />
                  If your return is approved, we will initiate a refund to your
                  credit card (or original method of payment).
                  <br />
                  <br />
                  You will receive the credit within a certain amount of days,
                  depending on your card issuer's policies.
                </p>
              ),
            },
          ]}
        />
      </GridItem>
    );
  }
}

export default withStyles(tabsStyle)(ProductTabs);
