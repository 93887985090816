import React from "react";
import { navigateTo } from "gatsby-link";
import TextField from "@material-ui/core/TextField";
import Button from "../CustomButtons/Button.jsx";
import styled from "@emotion/styled";

export const ButtonText = styled.div`
word-spacing: 0.25rem;
letter-spacing: 0.15rem; 
  }
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class OosForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <div>
        <form
          name="Please notify me when product is back in stock"
          method="post"
          action="/products/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p>
          <TextField
            id="outlined-helperText"
            label="Notify me when available"
            helperText="Submit your email address and we will notify you when this product is back in stock."
            variant="outlined"
            name="email"
            type="email"
            onChange={this.handleChange}
          />
          <Button color="white" size="xs" type="submit">
            <ButtonText>Submit</ButtonText>
          </Button>
        </form>
      </div>
    );
  }
}

export default OosForm;
